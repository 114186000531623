<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Información del viaje</b-card-title>
      <b-card-sub-title>En este modulo puedes cambiar toda la información asociada a tu viaje</b-card-sub-title>
      <hr>
      <b-alert
        variant="danger"
        :show="isEditTraveler"
      >
        <h4 class="alert-heading">
          Este usuario no posee datos de viaje
        </h4>
      </b-alert>
      <validation-observer
        ref="TravelerForm"
      >
        <vue-traveler
          v-if="userData && userData.viaje != null"
          :traveler-data="userData"
          @input-tipo-documento-id="setTipoCargo"
          @input-tarjeta-identidad="setTipoCargo"
          @input-expedicion="setTipoCargo"
          @input-pasaporte="setTipoCargo"
          @input-pasp-expedicion="setTipoCargo"
          @input-pasp-vencimiento="setTipoCargo"
          @input-direccion="setTipoCargo"
          @input-date-of-birth="setTipoCargo"
          @input-lugar-nacimiento="setTipoCargo"
          @input-pais-id="setTipoCargo"
          @input-ciudad-id="setTipoCargo"
          @input-municipio-id="setTipoCargo"
          @input-tipo-stado-civil-id="setTipoCargo"
          @input-fecha-viaje-id="setTipoCargo"
          @input-tipo-gender="setTipoCargo"
          @input-tipo-grado-academico-id="setTipoCargo"
          @input-institucion-academica="setTipoCargo"
          @input-fecha-terminacion="setTipoCargo"
          @input-carrera-estudio="setTipoCargo"
          @input-visa-americana="setTipoCargo"
          @input-tipo-financiacion-id="setTipoCargo"
          @input-tipo-estudio-id="setTipoCargo"
          @input-preparatoria="setTipoCargo"
          @input-celular="setTipoCargo"
          @input-telefono="setTipoCargo"
          @input-universidad-id="setTipoCargo"
          @input-universidad-segunda-id="setTipoCargo"
          @input-pais-id-universidad="setTipoCargo"
          @input-pais-id-universidad-segunda="setTipoCargo"
          @input-ciudad-universidad-id="setTipoCargo"
          @input-ciudad-universidad-segunda-id="setTipoCargo"
          @input-date-of-irlanda="setTipoCargo"
          @input-semanas="setTipoCargo"
        />
      </validation-observer>
      <footer>
        <div class="w-100">
          <b-button
            v-if="$can('update', 'viaje') && !isEditTraveler"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="float-right ml-1"
            @click="saveTraveler"
          >
            Actualizar
          </b-button>
        </div>
      </footer>
    </b-card-body>
  </b-card>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import {
  BCard, BCardBody, BCardTitle, BCardSubTitle, BButton, BAlert,
} from 'bootstrap-vue'
import IndexTraveler from '@/views/users/traveler/TravelerEdit.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, integer, alpha, alphaSpaces, email, ext,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import userStoreModule from '@/views/users/user/userStoreModule'

export default {
  name: 'Traveler',
  components: {
    ValidationObserver,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BButton,
    BAlert,
    'vue-traveler': IndexTraveler,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
  },
  data() {
    return {
      // viaje
      carrera_estudio: null,
      carrera_estudio_segunda_opcion: null,
      institucion_academica: null,
      fecha_terminacion: null,
      preparatoria: null,
      lugar_nacimiento: null,
      tarjeta_identidad: null,
      expedicion: null,
      pasaporte: null,
      celular: null,
      telefono: null,
      direccion: null,
      date_of_birth: null,
      pasp_expedicion: null,
      pasp_vencimiento: null,
      visa_americana: null,
      tipo_gender: null,
      tipo_documento_id: null,
      municipio_id: null,
      ciudad_id: [],
      pais_id: null,
      tipo_financiacion_id: null,
      tipo_estudio_id: null,
      pais_id_universidad: null,
      pais_id_universidad_segunda: null,
      ciudad_universidad_id: null,
      ciudad_universidad_segunda_id: null,
      universidad_id: null,
      universidad_segunda_id: null,
      fecha_viaje_id: null,
      tipo_stado_civil_id: null,
      tipo_grado_academico_id: null,
      semanas: null,
      date_of_irlanda: null,

      userData: null,
      // validation rules
      required,
      integer,
      alpha,
      alphaSpaces,
      email,
      ext,
    }
  },
  computed: {
    isEditTraveler() {
      return this.userData && this.userData.viaje == null
    },
  },
  mounted() {
    this.setDataUser()
  },
  methods: {
    setDataUser() {
      const userId = JSON.parse(localStorage.getItem('userData'))
      store.dispatch('app-user/fetchUser', { id: userId.id })
        .then(response => {
          this.userData = response.data.userData
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
    setTipoCargo(value) {
      this.[value.key] = value.info
    },
    saveTraveler() {
      this.$refs.TravelerForm.validate().then(success => {
        if (success) {
          const dataTraveler = {
            id: this.userData.id,
            carrera_estudio: this.carrera_estudio,
            carrera_estudio_segunda_opcion: this.carrera_estudio_segunda_opcion,
            institucion_academica: this.institucion_academica,
            fecha_terminacion: this.fecha_terminacion,
            preparatoria: this.preparatoria.length > 0 ? this.preparatoria[0].id : this.preparatoria.id,
            lugar_nacimiento: this.lugar_nacimiento,
            tarjeta_identidad: this.tarjeta_identidad,
            expedicion: this.expedicion,
            pasaporte: this.pasaporte,
            celular: this.celular,
            telefono: this.telefono,
            direccion: this.direccion,
            date_of_birth: this.date_of_birth,
            pasp_expedicion: this.pasp_expedicion,
            pasp_vencimiento: this.pasp_vencimiento,
            visa_americana: this.visa_americana.length > 0 ? this.visa_americana[0].id : this.visa_americana.id,
            tipo_gender: this.tipo_gender ? this.tipo_gender.id : this.tipo_gender,
            tipo_documento_id: this.tipo_documento_id ? this.tipo_documento_id.id : this.tipo_documento_id,
            municipio_id: this.municipio_id ? this.municipio_id.id : this.municipio_id,
            ciudad_id: this.ciudad_id ? this.ciudad_id.id : this.ciudad_id,
            pais_id: this.pais_id ? this.pais_id.id : this.pais_id,
            tipo_financiacion_id: this.tipo_financiacion_id ? this.tipo_financiacion_id.id : this.tipo_financiacion_id,
            tipo_estudio_id: this.tipo_estudio_id ? this.tipo_estudio_id.id : this.tipo_estudio_id,
            pais_id_universidad: this.pais_id_universidad ? this.pais_id_universidad.id : this.pais_id_universidad,
            pais_id_universidad_segunda: this.pais_id_universidad_segunda ? this.pais_id_universidad_segunda.id : this.pais_id_universidad_segunda,
            ciudad_universidad_id: this.ciudad_universidad_id ? this.ciudad_universidad_id.id : this.ciudad_universidad_id,
            ciudad_universidad_segunda_id: this.ciudad_universidad_segunda_id ? this.ciudad_universidad_segunda_id.id : this.ciudad_universidad_segunda_id,
            universidad_id: this.universidad_id ? this.universidad_id.id : this.universidad_id,
            universidad_segunda_id: this.universidad_segunda_id ? this.universidad_segunda_id.id : this.universidad_segunda_id,
            fecha_viaje_id: this.fecha_viaje_id ? this.fecha_viaje_id.id : this.fecha_viaje_id,
            tipo_stado_civil_id: this.tipo_stado_civil_id ? this.tipo_stado_civil_id.id : this.tipo_stado_civil_id,
            tipo_grado_academico_id: this.tipo_grado_academico_id ? this.tipo_grado_academico_id.id : this.tipo_grado_academico_id,
            date_of_irlanda: this.date_of_irlanda,
            semanas: this.semanas,
          }
          this.updateTraveler(dataTraveler)
        }
      })
    },
    updateTraveler(data) {
      this.$http.put(`/v1/users/single/viaje`, data).then(res => {//eslint-disable-line
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Se ha actualizado satisfactoriamente',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: 'El registro se ha actualizado.',
          },
        },
        {
          position: 'top-center',
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'XIcon',
            variant: 'danger',
            text: error.response.data.message,
          },
        },
        {
          position: 'bottom-right',
        })
      })
    },
  },
}
</script>

<style>

</style>
